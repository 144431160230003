import 'photoswipe/dist/photoswipe.css';
import './App.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

const pictures = [
  { pictureName: 'formal_1.jpg', caption: 'UUCS first formal' },
  { pictureName: 'formal_2.jpg', caption: 'UUCS first formal' },
  { pictureName: 'formal_3.jpg', caption: 'UUCS first formal' },
  { pictureName: 'formal_4.jpg', caption: 'UUCS first formal' },
  { pictureName: 'formal_5.jpg', caption: 'UUCS first formal' },
  { pictureName: 'formal_6.jpg', caption: 'UUCS first formal' },
  { pictureName: 'formal_7.jpg', caption: 'UUCS first formal' },
  { pictureName: 'formal_8.jpg', caption: 'UUCS first formal' },
  { pictureName: 'pizza_1.JPG', caption: 'Pizza night' },
  { pictureName: 'pizza_2.JPG', caption: 'Pizza night' },
  { pictureName: 'pizza_3.JPG', caption: 'Pizza night' },
  { pictureName: 'vr_1.JPG', caption: 'VR night' },
  { pictureName: 'vr_2.JPG', caption: 'VR night' },
  { pictureName: 'end_party_1.jpg', caption: 'End of Year Party' },
  { pictureName: 'end_party_2.jpg', caption: 'End of Year Party' },
];

function Pictures() {
  return (
    <div className="pictures_div">
      <Gallery withCaption>
        <div
          style={{
            display: 'flex',
            gap: '1.5em',
            padding: '3em',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
          }}
        >
          {pictures.map(picture => (
            <Item
              key={picture.pictureName}
              original={`https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Picture/tr:w-1920,h-1080,fo-auto/${picture.pictureName}`}
              thumbnail={`https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Picture/tr:w-320,h-180,fo-auto/${picture.pictureName}`}
              width="1920"
              height="1080"
              caption={picture.caption}
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  onClick={open}
                  src={`https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Picture/tr:w-320,h-180,fo-auto/${picture.pictureName}`}
                  alt={picture.caption}
                />
              )}
            </Item>
          ))}
        </div>
      </Gallery>
    </div>
  );
}

export default Pictures;
