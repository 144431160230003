import Fade from 'react-reveal/Fade';
import { Carousel } from 'react-bootstrap';
import quotes from './quotes';
import CoolQuotes from './CoolQuotes';

function MainPage() {
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/tr:w-1920,h-1080,fo-auto/uu_computing_society_first_formal.jpg"
            alt="Some participants of the Ulster University Formal 2022."
          />
          <Carousel.Caption>
            <h2>Ulster University Computing Society First Formal</h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/tr:w-1920,h-1080,fo-auto/cybersecurity_hackathon_ibm_PSMx_GO7e.jpg"
            alt="Participants of IBM Hackathon in Ulster University."
          />
          <Carousel.Caption>
            <h2>UUCS CyberSecurity Hackathon with IBM</h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/tr:w-1920,h-1080,fo-auto/sistem2022.jpg"
            alt="Organizers of System 2022 Conference."
          />
          <Carousel.Caption>
            <h2>SISTEM 2022</h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/tr:w-1920,h-1080,fo-auto/LibertyITHackathon.png"
            alt="All participants of Liberty IT hackathon in a Microsoft Teams call."
          />
          <Carousel.Caption>
            <h2>Liberty IT Hackathon</h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/tr:w-1920,h-1080,fo-auto/AllstateNIHackathon.png"
            alt="All participants of Allstate NI hackathon in a Microsoft Teams call."
          />
          <Carousel.Caption>
            <h2>AllstateNI Hackathon</h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/tr:w-1920,h-1080,fo-auto/Bazaarvoice_coding_challenge.png"
            alt="Bazaar Voice coding challenge in progress. Screenshot of a team sharing code over a call."
          />
          <Carousel.Caption>
            <h2>Bazaarvoice Coding Challenge</h2>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Fade bottom>
        <div
          style={{ padding: '3em', justifyContent: 'center', display: 'flex' }}
        >
          <CoolQuotes quotes={quotes} pageTimeout={5000} />
        </div>
      </Fade>
      <Fade bottom>
        <div
          style={{
            paddingTop: '1.5em',
            paddingLeft: '1.5em',
            paddingBottom: '2em',
          }}
        >
          <h1 style={{ fontFamily: 'Noto Sans', fontSize: '50px' }}>
            Previous Sponsors
          </h1>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1em',
              padding: '1em',
            }}
          >
            <img
              src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Logo/tr:h-170,fo-auto/Liberty_IT_logo.webp"
              alt="Liberty IT Company Logo"
            />
            <img
              src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Logo/tr:h-170,fo-auto/Allstate_NI_logo.jpeg"
              alt="Allstate NI Company Logo"
            />
            <img
              src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Logo/tr:h-170,fo-auto/Bazaarvoice_logo.jpeg"
              alt="Bazaar Voice Company Logo"
            />
            <img
              src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Logo/tr:h-170,fo-auto/Instil_logo.webp"
              alt="Instil Company Logo"
            />
            <img
              src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Logo/tr:h-170,fo-auto/trailstone_logo.png"
              alt="Trailstone Company Logo"
            />
            <img
              src="https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Logo/tr:h-170,fo-auto/galvia_digital_logo.jpeg"
              alt="Galvia Digital Company Logo"
            />
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default MainPage;
