import CoolQuote from '../CoolQuote/CoolQuote';
import Pagination from './lib/Pagination';
import { useEffect, useState } from 'react';
import styles from './CoolQuotes.module.css';

/** @typedef {import("../types").CoolQuoteItem} CoolQuoteItem */
/** @typedef {{ quotes: CoolQuoteItem[]; pageTimeout?: number; }} CoolQuotesProps */

/** @param {CoolQuotesProps} props */
const CoolQuotes = ({ quotes, pageTimeout = 3000 }) => {
  const numOfPages = quotes.length;
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentPage((currentPage + 1) % numOfPages);
    }, pageTimeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentPage, numOfPages, pageTimeout]);

  return (
    <div className={styles.container}>
      <div className={styles.quotes}>
        {quotes.map((quote, index) => (
          <div
            key={`${quote.email}-${quote.quote}`}
            className={index !== currentPage ? styles.hidden : ''}
          >
            <CoolQuote
              author={quote.author}
              jobTitle={quote.jobTitle}
              quote={quote.quote}
              email={quote.email}
              linkedin={quote.linkedin}
            />
          </div>
        ))}
      </div>
      <Pagination numOfPages={numOfPages} currentPage={currentPage} />
    </div>
  );
};

export default CoolQuotes;
