import { Card } from 'react-bootstrap';
import people from './people';

function Team() {
  return (
    <div
      style={{
        display: 'flex',
        gap: '1.5em',
        padding: '3em',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        backgroundColor: '#222831',
      }}
    >
      {people.map(person => (
        <Card key={person.name + person.email} style={{ width: '18rem' }}>
          <Card.Img variant="top" src={person.image} />
          <Card.Body>
            <Card.Title>{person.name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {person.subtitle}
            </Card.Subtitle>
            <Card.Text>{person.text}</Card.Text>
            <Card.Link href={person.email}>Email</Card.Link>
            <Card.Link href={person.linkedin}>LinkedIn</Card.Link>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default Team;
