import styles from './Pagination.module.css';

/** @typedef {{ numOfPages: number; currentPage: number; }} PaginationProps */

/** @param {PaginationProps} props */
const Pagination = ({ numOfPages, currentPage }) => {
  const arr = Array.from({ length: numOfPages }, (_, i) => i + 1);

  return (
    <div className={styles.container}>
      {arr.map((_, i) => (
        <div
          key={crypto.randomUUID()}
          className={`${styles.paginationUnit} ${
            i === currentPage ? styles.selected : ''
          }`}
        />
      ))}
    </div>
  );
};

export default Pagination;
