import Fade from 'react-reveal/Fade';
import { Button } from 'react-bootstrap';

function AboutUs() {
  return (
    <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Fade>
          <h1
            style={{
              fontFamily: 'Noto Sans',
              fontSize: '50px',
            }}
          >
            Who are we?
          </h1>
        </Fade>
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'center', minHeight: '50vh' }}
      >
        <Fade>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '5em',
            }}
          >
            <h3 style={{ maxWidth: '20em', fontFamily: 'IBM Plex Sans' }}>
              Ulster Univeristy Computing Society is a student lead computing
              society. It was set up in April 2018. We are a society with both
              social and academic values.
              <br />
              <br />
              We are the first BCS student chapter in Northern Ireland. Each
              year we have many workshops, seminars, Hackathons and coding
              challenges.
              <br />
              <br />
              Our aim is to help the students to improve their technical skills
              and 'soft' skills today's industry requires, also to provide a
              networking environment for the students to meet each other.
            </h3>
            <img
              src="Logo.png"
              alt="Computing Society Logo."
              style={{ width: '300px' }}
            />
          </div>
        </Fade>
      </div>

      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Fade>
          <h1
            style={{
              fontFamily: 'Noto Sans',
              fontSize: '50px',
            }}
          >
            But that's not all...
          </h1>
        </Fade>
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'center', height: '50vh' }}
      >
        <Fade>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              wordWrap: 'break-word',
              alignItems: 'center',
              gap: '15em',
            }}
          >
            <h3 style={{ maxWidth: '20em', fontFamily: 'IBM Plex Sans' }}>
              Each semester, we have many social events such as: <br />
              <img
                src="https://img.icons8.com/color/38/000000/beer.png"
                alt="Beer Icon."
              />
              pub night
              <br />
              <img
                src="https://img.icons8.com/doodle/38/000000/pizza--v1.png"
                alt="Pizza Icon."
              />
              pizza night
              <br />
              <img
                src="https://img.icons8.com/nolan/38/virtual-reality.png"
                alt="Virtual Reality Icon."
              />
              VR night
              <br />
              <img
                src="https://img.icons8.com/color/38/000000/dancing-party--v1.png"
                alt="Dancing Icon."
              />
              End of year party
              <br />
              <img
                src="https://img.icons8.com/external-gradients-pongsakorn-tan/38/000000/external-formal-clothing-gradients-pongsakorn-tan.png"
                alt="Icon of Formal Jacket Respresenting Formal."
              />
              Formal
              <br />
              <br />
              If there is any events that you would like the society to
              organise, please feel free to contact us.
            </h3>
          </div>
        </Fade>
      </div>
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Fade>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1
              style={{
                fontFamily: 'Noto Sans',
                fontSize: '50px',
              }}
            >
              Come join us today!
              <br />
              <br />
            </h1>
            <Button
              variant="outline-primary"
              href="https://www.uusu.org/organisation/uujcomputingsoc/"
            >
              Join us
            </Button>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default AboutUs;
