import './App.css';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import Team from './Team';
import MainPage from './MainPage';
import Pictures from './Pictures';
import AboutUs from './AboutUs';
import { Link, Route } from 'wouter';

function App() {
  return (
    <div>
      <header>
        <Navbar
          collapseOnSelect
          expand="lg"
          style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}
        >
          <Container>
            <Navbar.Brand href="#home">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Link href="/">
                <img
                  src="Logo.png"
                  width="100"
                  height="100"
                  className="d-inline-block align-top"
                  alt="Ulster Uniersity Computing Society Logo."
                />
              </Link>{' '}
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Link href="/">
                  <Nav.Link>Home</Nav.Link>
                </Link>
                <Link href="/pictures">
                  <Nav.Link>Pictures</Nav.Link>
                </Link>
                <Link href="/team">
                  <Nav.Link>Team</Nav.Link>
                </Link>
                <Link href="/about">
                  <Nav.Link>About Us</Nav.Link>
                </Link>
              </Nav>
              <div
                style={{
                  paddingRight: '2em',
                  display: 'flex',
                  gap: '15px',
                  alignItems: 'baseline',
                }}
              >
                <div>Contact Us:</div>
                <a href="mailto:computingsocietyuuj@gmail.com">
                  <i
                    class="bi bi-envelope-fill"
                    style={{ fontSize: '1.5rem' }}
                  />
                </a>
                <a href="https://www.facebook.com/computingsocietyuu/">
                  <i class="bi bi-facebook" style={{ fontSize: '1.5rem' }} />
                </a>
                <a href="https://www.instagram.com/computinguu/">
                  <i class="bi bi-instagram" style={{ fontSize: '1.5rem' }} />
                </a>
                <a href="https://www.linkedin.com/company/72161700/">
                  <i class="bi bi-linkedin" style={{ fontSize: '1.5rem' }} />
                </a>
              </div>
              <Button
                variant="outline-primary"
                href="https://www.uusu.org/organisation/uujcomputingsoc/"
              >
                Join us
              </Button>{' '}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <Route path="/" component={MainPage} />
      <Route path="/pictures" component={Pictures} />
      <Route path="/team" component={Team} />
      <Route path="/about" component={AboutUs} />

      <div class="footer-dark">
        <footer>
          <div class="container">
            <div class="row">
              <div class="col-md-6 item text">
                <h3>About Us</h3>
                <p>
                  Ulster University Computing Society was set up in April 2018.
                  You're super welcome to get involved! We are a society with
                  both social and academic values.
                </p>
              </div>
              <div class="col item social">
                <a href="mailto:computingsocietyuuj@gmail.com">
                  <i
                    class="bi bi-envelope-fill"
                    style={{ fontSize: '1.5rem' }}
                  />
                </a>
                <a href="https://www.facebook.com/computingsocietyuu/">
                  <i class="bi bi-facebook" style={{ fontSize: '1.5rem' }} />
                </a>
                <a href="https://www.instagram.com/computinguu/">
                  <i class="bi bi-instagram" style={{ fontSize: '1.5rem' }} />
                </a>
                <a href="https://www.linkedin.com/company/72161700/">
                  <i class="bi bi-linkedin" style={{ fontSize: '1.5rem' }} />
                </a>
              </div>
              <Button
                variant="outline-primary"
                href="https://www.uusu.org/organisation/uujcomputingsoc/"
                style={{ maxWidth: '50%' }}
              >
                Join us
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
