import styles from "./CoolQuote.module.css";

/** @typedef {import("../types").CoolQuoteItem} CoolQuoteItem */
/** @typedef {CoolQuoteItem} CoolQuoteProps */

/** @param {CoolQuoteProps} props */
function CoolQuote({ quote, author, jobTitle, email, linkedin }) {
  return (
    <div className={styles.container}>
      <div className={styles.quote}>{`"${quote}"`}</div>
      <div className={styles.author}>{author}</div>
      <div className={styles.jobTitle}>{jobTitle}</div>
      <div className={styles.socialMedia}>
        {email && (
          <a href={`mailto:${email}`}>
            <i className="bi bi-envelope-fill" style={{ fontSize: "2rem" }} />
          </a>
        )}
        {linkedin && (
          <a href={linkedin}>
            <i className="bi bi-linkedin" style={{ fontSize: "2rem" }} />
          </a>
        )}
      </div>
    </div>
  );
}

export default CoolQuote;
