const people = [
    {
      name: "Yuan Zhang",
      subtitle: "Chair",
      image: 'https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Team/tr:w-500,h-500/yuan.jpg',
      text: "",
      email: "mailto:mitchellcheung1@yahoo.com",
      linkedin: "https://www.linkedin.com/in/yuan-zhang-965aaa141/",
    },
    {
      name: "Andrew Bradley",
      subtitle: "Vice-Chair",
      image: 'https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Team/tr:w-500,h-500/andrew.jpeg',
      text: "",
      email: "mailto:bradley-a41@ulster.ac.uk",
      linkedin: "https://www.linkedin.com/in/andrew-bradley-360940192/",
    },
    {
      name: "Christopher Green",
      subtitle: "Event Coordinator",
      image: 'https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Team/tr:w-500,h-500/chris.jpeg',
      text: "",
      email: "mailto:chriswgreen13@gmail.com",
      linkedin: "https://www.linkedin.com/in/christopher-green-252897113/",
    },
    {
      name: "Sohail Nasir ",
      subtitle: "Head of Hackathon",
      image: 'https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Team/tr:w-500,h-500/sohail.jpeg',
      text: "",
      email: "mailto:Sohailnasir1025@outlook.com",
      linkedin: "https://www.linkedin.com/in/sohail-nasir-a61a43201/",
    },
    {
      name: "Samara Sprott",
      subtitle: "Committee member",
      image: 'https://ik.imagekit.io/uucomputingsociety/ComputingSociety-site/Team/tr:w-500,h-500/samara.jpg',
      text: "",
      email: "mailto:Sprott-S@ulster.ac.uk",
      linkedin: "https://www.linkedin.com/",
    }
  ];

  export default people;