/** @type {import('./types').CoolQuoteItem[]} */
const quotes = [
  {
    author: 'Gary Moore',
    quote:
      'It has been a joy working with Yuan and the Computing Society organising a Cybersecurity Hackathon. It was great to see new faces keen to learn more about tech.',
    jobTitle: 'Software Developer',
    linkedin: 'https://www.linkedin.com/in/gary-moore-524090167/',
  },
];

export default quotes;
